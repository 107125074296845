import './main.less';
// have to disable eslint because of ordering of import
import startDKEApp from 'dke-apps-web'; // eslint-disable-line import/first
import startCSCApp from 'fo-cic-web-aem-frontend'; // eslint-disable-line import/first


import 'moment/locale/de'; // eslint-disable-line import/first

import routes from './routes';

startDKEApp();
startCSCApp(routes);

